body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.tutorial {
    margin-top: 120px;
}

.tutorial-page {
    position: relative;
    width: 100%;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    margin-left: 50px;
    margin-top: 1px;
}

.image-container {
    display: flex;
    flex-direction: column;
    width: 100%; 
}

.image-wrapper {
    width: 100%; 
    height: 100vh; 
    scroll-snap-align: start;
    padding: 20px; 
    display: flex; 
    align-items: center; 
    justify-content: center; 
    box-sizing: border-box; 
}

.image-wrapper img {
    width: 100%; 
    height: auto; 
    max-height: 100vh;
    object-fit: cover; 
}

.youtube-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #FF0000;
    color: #fff;
    padding: 15px 30px;
    border-radius: 30px;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    display: flex;
    align-items: center;
    z-index: 2;
}

.youtube-button i {
    margin-right: 10px;
    font-size: 24px;
}

.youtube-button:hover {
    transform: scale(1.1);
    background-color: #cc0000;
}

.youtube-button:active {
    transform: scale(0.95);
}



.download-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #9d9c9c;
    color: #fff;
    padding: 15px 30px;
    border-radius: 30px;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    display: flex;
    align-items: center;
    z-index: 2;
    margin-bottom:70px ;
}

.download-button i {
    margin-right: 10px;
    font-size: 24px;
}

.download-button:hover {
    transform: scale(1.1);
    background-color: #848484;
}

.download-button:active {
    transform: scale(0.95);
}
.category {
    margin-bottom: 40px;
    padding: 50px;
}

.category h2 {
    font-size: 24px;
    margin-bottom: 10px;
    padding-left: 50px;
}

nav {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 150px;
    margin-right: 10px;
    z-index: 1;
}

nav button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

nav button:hover {
    background-color: #0056b2;
}

.section-title-2 h6 {
    animation: fadeInUp 0.6s ease-out;
}

.section-title-2 .title {
    color: #000c35;
    font-size: 40px;
}

/* Responsive Styles */

/* For tablets */
@media (max-width: 768px) {
    .tutorial-page {
        margin-left: 10px;
    }

    .image-wrapper {
        padding: 0; /* Remove padding for better fit */
        height: 22vh; /* Adjust height for tablets */
    }
    

    .youtube-button {
        padding: 12px 25px;
        font-size: 16px;
        bottom: 15px;
        right: 15px;
    }

    .youtube-button i {
        font-size: 20px;
    }
    .download-button i {
        font-size: 20px;
    }

    .category h2 {
        font-size: 22px;
        padding-left: 20px;
        margin-bottom: 140px;
    }

    nav {
        margin-top: 100px;
        top: 10px;
        left: 10px;
    }
    .category {
        margin-bottom: 60px;
        padding: 50px;
    }
    nav button {
        padding: 8px 15px;
        font-size: 14px;
    }
}

/* For phones */
@media (max-width: 480px) {
    .tutorial-page {
        margin-left: 5px;
    }

    .image-wrapper {
        padding: 0; /* Remove padding for better fit */
        height: 20vh; /* Adjust height for phones */
    }

    .youtube-button {
        padding: 10px 20px;
        font-size: 14px;
        bottom: 10px;
        right: 10px;
    }
    .category {
        padding: 0px 50px;
    }

    .youtube-button i {
        font-size: 18px;
    }
    .download-button {
        padding: 10px 20px;
        font-size: 14px;
        bottom: 10px;
        right: 10px;
    }

    .download-button i {
        font-size: 18px;
    }

    .category h2 {
        font-size: 20px;
        padding-left: 10px;
        margin-bottom: 10px;
    }

    nav {
        margin-top: 80px;
        top: 5px;
        left: 5px;
    }

    nav button {
        padding: 6px 10px;
        font-size: 12px;
    }
}
