.pageqrparent{
  background-color: #fff;
  height: auto;
}
.pageqr{
  padding: 100px 0px;
  width: 70%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.Qr-phone {
  display: inline-flex;
  margin: auto;
  padding: 40px;
  animation: fadeIn 1s ease-in-out;
}

.Qr-card {
  display: inline-flex;
  flex-direction: column;
  background: #E9F1FA;
  border: 1px solid #ddd;
  max-height: 300px;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
  margin: 110px auto;
  padding: 40px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

@media (max-width: 700px) {
  .pageqr{
    padding: 25px 0px;
    width: 88%;
  }
  
}
@media (max-width: 1024px) {
  .Qr-card {
    width: 100%;
    margin: 100px auto;
    width: 100%;
    margin: 4px auto 30px auto;
  }
  .pageqr{
    flex-direction: column-reverse;
  }
  .Qr-phone {
    padding:0px 40px;
    margin: 100px auto 30px auto;
  }
  .animatedDot{
    position: absolute;
    margin-bottom: 100px;
  }
  .animatedDotone{
    position: absolute;
    left: 80vw;
  }
  
}

   
      
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
      
.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input, .input-group select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 16px;
  transition: border-color 0.3s;
}

.input-group input:focus, .input-group select:focus {
  border-color: #007bff;
}

.generate-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.generate-btn:hover {
  background-color: #0056b3;
}

.qrcode { 
  position: absolute;
  margin: 185px 70px;
  object-fit: cover;
  /* padding-left: 980px; */
  
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.mockup{
  margin-left: 915px;
  padding-top: 50px;
  z-index: 1;
  position: absolute;
  transform: translate();
  animation: slideIn 1s ease-in-out;
  
}


.validation-message {
  color: red;
  font-size: 12px;
  margin-bottom: 5px;
}

