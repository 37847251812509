.pagestore{
  margin-top: 90px;
  background-color: #eaeaea8d;
}
.animatedDot{
  position: absolute;
  margin-bottom: 100px;
}
.products-container {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px;
  
}

  
.product-card {
  background: #E9F1FA;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  margin-bottom: 10px;
  width: 400px;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s ease-out forwards;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transition: transform 0.3s, box-shadow 0.5s;
  cursor: pointer;
}
  
.product-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.product-image {
  width: 100%;
  height: 200px;
  width: 700px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .product-card {
    width: 100%;
  }
}
  
.product-card:hover .product-image {
  transform: scale(1.1);
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.section-title-2 h2 {
  animation: fadeInUp 0.6s ease-out;
}

.product-card h2,
.product-card p {
  opacity: 0;
  transform: translateX(-100px);
  animation: textSlideIn 0.6s ease-out forwards;
}

@keyframes textSlideIn {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.cta-button {
  padding: 10px 20px;
  border: none;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #45a049;
}

.pagestore {
  transition: background-color 0.3s ease;
}

.category-title {
  font-size: 24px;
  margin-top: 20px;
  color: #333;
  padding-bottom: 20px;
}

.category-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  
}




